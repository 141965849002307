import Axios from "axios";

const actions = {
  async actMenuTableGets(store, ordertable) {
    return await Axios.get(
      `${process.env.VUE_APP_API_URL}/menuTable/getAllOnline`,
      ordertable
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actMenuTableShow(store, ordertable) {
    return await Axios.get(
      `${process.env.VUE_APP_API_URL}/menuTable/getAllShowOnline`,
      ordertable
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actOrderLineCreate(store, ordertable) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/orderLine/createOnline`,
      ordertable
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actOrderLineGet(store, ordertable) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/orderLine/getLineOnline`,
      ordertable
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actOrderLineGetRound(store, ordertable) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/orderLine/getLineByRoundOnline`,
      ordertable
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actOrderLineRound(store, ordertable) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/orderLine/getRoundOnline`,
      ordertable
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actOrderLogin(store, ordertable) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/orderTable/loginOnline`,
      ordertable
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actOrderTableGet({ commit }, ordertable) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/orderTable/getAllOnline`,
      ordertable
    )
      .then((response) => {
        commit("SET_ORDERTABLE", response.data.result[0]);
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actOrderTBLTable(store, ordertable) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/order-tbl-table-online-order`,
      ordertable
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async actParameterTable({ commit }) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/parameterTable/getAll`)
      .then((response) => {
        commit("SET_PARAMETERTABLE", response.data.result[0]);
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
};

export default actions;
