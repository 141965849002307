import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#2c5042",
        secondary: "#CDF1E3",
        accent: "#46b087",
        error: colors.red.accent3,
      },
      dark: {
        primary: "#2c5042",
      },
    },
  },
});
