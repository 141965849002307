import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import mixinJS from "./js/mixin";
import VueSimpleAlert from "vue-simple-alert";
import loading from 'vuejs-loading-screen';

Vue.mixin(mixinJS);
Vue.config.productionTip = false
Vue.use(loading);

Vue.use(VueSimpleAlert);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
