const mutations = {
  SET_PARAMETERTABLE(state, data) {
    state.parametertable = Object.assign({}, state.parametertable, data);
  },
  SET_ORDERTABLE(state, data) {
    state.ordertable = Object.assign({}, state.ordertable, data);
  },
};

export default mutations;
