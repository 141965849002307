export const newParameterTable = () => {
  return {
    CompanyName: "",
    CompanyAddress: "",
    CompanyTaxId: "",
    CompanyPhone: "",
    AdultPrice: "",
    ChildPrice: "",
    DiningTime: "",
    Status: "",
    VatType: "",
    PathImage: "",
    LastOrderTime: "",
    LastOrderTimeStr: "",
  };
};

export const newOrderTable = () => {
  return {
    OrderId: "",
    OrderDateStr: "",
    OrderTime: "",
    OrderTimeEnd: "",
    GenQr: "",
    QtyPerson: "",
    QtyChild: "",
    QtyChildFree: "",
    PromotionId: "",
    LineTotal: "",
    TotalBeforeDiscount: "",
    DiscountPercent: "",
    DiscountAmount: "",
    DiscountTotal: "",
    Total: "",
    Rounding: "",
    TotalInVat: "",
    Net: "",
    TotalAmount: "",
    RefillDrink: "",
  };
};

const state = () => ({
  ordertable: newOrderTable(),
  parametertable: newParameterTable(),
});

export default state;
